<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>上游协议</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="sub-sponsor-info">
            <span>上游协议</span>
           <div class="flex">
               <div class="sub-sponsor-container">
                   <div class="input-item">
                       <div class="input-item-title">{{$getTitleByType('会议名称',pid.cid)}}</div>
                       <el-select ref="pName" v-model="pName" filterable :placeholder="`请选择会议名称${$getTitleByType('会议名称',pid.cid)}）`"
                                  @change="classifyChange($event, 0)">
                           <el-option v-for="(item,index) in projectOptions" :key="index"
                                      :label="item.name" :value="index">
                           </el-option>
                       </el-select>
                   </div>
                   <div class="input-item">
                       <div class="input-item-title">所属学会</div>
                       <el-select v-model="society" :placeholder="`请选择所属学会（请先选择${$getTitleByType('会议名称',pid.cid)}）`"
                                  @change="classifyChange($event, 1)" @visible-change="classifyClick($event, 1)">
                           <el-option v-for="item in societyOptions" :key="item.id"
                                      :label="item.name" :value="item.id">
                           </el-option>
                       </el-select>
                   </div>
                   <div class="input-item">
                       <div class="input-item-title">{{$getTitleByType('会议日期',pid.cid)}}</div>
                       <el-input v-model="pid.time" :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`" disabled></el-input>
                   </div>
                   <div class="input-item">
                       <div class="input-item-title">{{ $getTitleByType('会议', pid.cid) }}形式</div>
                       <el-input v-model="pid.meet_form_str" :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`" disabled></el-input>
                   </div>
                   <div class="input-item">
                       <div class="input-item-title">{{$getTitleByType('大会主席',pid.cid)}}</div>
                       <el-input v-model="pid.chairman" :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                                 disabled></el-input>
                   </div>
                   <div class="input-item">
                       <div class="input-item-title">招商申请日期</div>
                       <el-select v-model="zs_id" :placeholder="`请选择招商申请日期（请先选择${$getTitleByType('会议名称',pid.cid)}）`"
                                  @visible-change="classifyClick($event, 2)" @change="classifyChange($event, 2)">
                           <el-option v-for="item in merchantsOptions" :key="item.id"
                                      :label="item.name + '(' + item.corporate_name + ')'" :value="item.id">
                           </el-option>
                       </el-select>
                   </div>
                   <div class="input-item">
                       <div class="input-item-title">支持类别</div>
                       <el-radio-group v-model="categories" disabled>
                           <el-radio :label="1">赞助</el-radio>
                           <el-radio :label="2">捐赠</el-radio>
                       </el-radio-group>
                   </div>
                   <div class="input-item">
                       <div class="input-item-title">企业名称(全称)</div>
                       <el-autocomplete class="inline-input" v-model="corporate_name"
                                        :fetch-suggestions="querySearch" placeholder="请输入企业名称全称"
                                        :trigger-on-focus="false" value-key="corporate_name"></el-autocomplete>
                   </div>
                   <div class="input-item">
                       <div class="input-item-title">企业备注</div>
                       <el-input v-model="corporate_name_remarks" placeholder="请输入企业备注"></el-input>
                   </div>
                   <div class="input-item">
                       <div class="input-item-title">联系人</div>
                       <el-autocomplete
                           class="inline-input"
                           v-model="contacts"
                           :fetch-suggestions="querySearchUser"
                           placeholder="请输入联系人"
                           :trigger-on-focus="false"
                           value-key="contacts"
                           disabled
                       ></el-autocomplete>
                   </div>
                   <div class="input-item">
                       <div class="input-item-title">协议金额</div>
                       <el-input
                           v-model="amounts"
                           type="number"
                           @mousewheel.native.prevent
                           placeholder="请输入协议金额"
                           @input="setAmount(0)"
                       ></el-input>
                       <div class="money">万元</div>
                   </div>
                   <div class="input-item">
                       <div class="input-item-title">专项金额</div>
                       <el-input
                           v-model="self_amount"
                           type="number"
                           @mousewheel.native.prevent
                           placeholder="请输入专项金额"
                           @input="setAmount(1)"
                       ></el-input>
                       <div class="money">万元</div>
                   </div>
                   <div class="input-item">
                       <div class="input-item-title">{{ $getTitleByType('会议金额',pid.cid) }}</div>
                       <el-input
                           ref="meeting_decimal"
                           v-model="meeting_decimal"
                           type="number"
                           @mousewheel.native.prevent
                           :placeholder="`请输入${$getTitleByType('会议金额',pid.cid)}`"
                           @input="setAmount(2)"
                       ></el-input>
                       <div class="money">万元</div>
                   </div>
                   <div class="input-item">
                       <div class="input-item-title">赞助回报项目</div>
                       <el-input v-model="zan_zhu_hui_bao" placeholder="请输入赞助回报项目"></el-input>
                   </div>
                   <div class="input-item">
                       <div class="input-item-title">加盖何种印章</div>
                       <el-select
                           v-model="seal_id"
                           placeholder="请选择加盖何种印章"
                           disabled>
                           <el-option
                               v-for="item in sealOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                           </el-option>
                       </el-select>
                   </div>

                   <div class="input-item">
                       <div class="input-item-desc">备注</div>
                       <el-input
                           type="textarea"
                           resize="none"
                           :rows="5"
                           v-model="remark"
                           placeholder="请输入备注"
                       ></el-input>
                   </div>
                   <div class="input-item">
                       <div class="input-item-desc">上传未盖章协议</div>
                       <div>
                           <div v-if="files.length > 0" class="upfile-list">
                               <ul v-for="(file, index) in files" :key="index">
                                   <li class="flex-center between-box">
                                       <a
                                           class="ovHide"
                                           :href="file.filePath | filePath"
                                           target="_blank"
                                       >{{ file.fileName }}</a
                                       >
                                       <i class="el-icon-delete" @click="closeFile(index)"></i>
                                   </li>
                               </ul>
                           </div>
                           <el-upload
                               :action="uploadPath"
                               :show-file-list="false"
                               :multiple="true"
                               :data="QiniuData"
                               :on-error="uploadError"
                               :on-success="uploadSuccess"
                               :before-upload="beforeAvatarUpload"
                           >
                               <el-button size="small" type="primary">点击上传文件</el-button>
                               <!--<div slot="tip" class="el-upload__tip">只能上传PDF文件</div>-->
                           </el-upload>
                       </div>
                   </div>
                   <div class="input-item">
                       <div class="input-item-title">
                           是否已核对协议金额与招商函匹配
                       </div>
                       <el-checkbox v-model="is_match">是</el-checkbox>
                   </div>
                   <div class="input-item">
                       <div class="input-item-title">协议状态</div>
                       <el-radio-group v-model="is_replied">
                           <el-radio :label="1">已回</el-radio>
                           <el-radio :label="0">未回</el-radio>
                       </el-radio-group>
                   </div>
                   <approvalList
                       :typeClassify="3"
                       :isNoHave="!id"
                       :society="society"
                       :copyMembers="copyMembers"
                       @setMember="setMember"
                       @setApproval="setApproval"
                   ></approvalList>

                   <el-button type="primary" @click="subApprovalSubmit" :loading="requestState" :disabled="requestState" >提交</el-button>
               </div>
               <div class="info-list flex flex-direction padding-sm margin-top-sm margin-left flex-sub" v-if="speLists.teshu.length + speLists.zhaoshang.length">
                  <div class="flex align-center">
                      <span class="tab" :class="{active:speTab ===0}" @click="speTab = 0">特殊审批</span>
                      <span class="tab margin-left-sm" :class="{active:speTab ===1}" @click="speTab = 1">招商资料</span>
                  </div>
                   <template v-if="speTab ===0">
                       <div class="info-item flex flex-direction" v-for="(item,index) in speLists.teshu" :key="index">
                           <div class="name">{{item.add_time}}</div>
                           <div class="remark margin-top-xs">{{item.remark}}</div>
                           <div class="file margin-top-xs">
                               <a v-for="(file, index) in item.files" :key="index" :href="file.filePath | filePath"
                                  target="_blank">{{ file.fileName }}</a>
                           </div>
                       </div>
                       <el-empty description="暂无数据" image-size="120" class="self-center margin-top-sm" v-if="!speLists.teshu.length"></el-empty>
                   </template>
                   <template v-if="speTab ===1">
                       <div class="info-item flex flex-direction" v-for="(item,index) in speLists.zhaoshang" :key="index">
                           <div class="name">{{item.add_time}}</div>
                           <div class="remark margin-top-xs">{{item.remark}}</div>
                           <div class="file margin-top-xs">
                               <a v-for="(file, index) in item.files" :key="index" :href="file.filePath | filePath"
                                  target="_blank">{{ file.fileName }}</a>
                           </div>
                       </div>
                       <el-empty description="暂无数据" image-size="120" class="self-center margin-top-sm"  v-if="!speLists.zhaoshang.length"></el-empty>
                   </template>
               </div>
           </div>
        </div>
    </div>
</template>

<script>
    import ElInput from "../../../node_modules/element-ui/packages/input/src/input.vue";
    import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
    import approvalList from "../../components/approvalList.vue";
    import {genUpToken,qiniuaddr,uploadPath} from "../../tools/CryptoJS";
    import {webUrl} from "../../tools";

    export default {
        components: {
            ElButton,
            ElInput,
            approvalList,
        },
        name: "subSponsor",
        data() {
            return {
                id: "",
                sign_code: "",
                society: "",
                societyOptions: [],
                pid: "",
                pName: "",
                projectOptions: [],
                zs_id: "",
                zs_amount: "",
                merchantsOptions: [],
                name: "",
                corporate_name: "",
                corporate_name_remarks: "",
                zan_zhu_hui_bao: "",
                contacts: "",
                amounts: "",
                self_amount: "",
                meeting_decimal: "",
                remark: "",
                categories: "",
                is_match: false,
                is_replied: 0,
                seal_id: 2,
                sealOptions: this.$tools.sealTypes(),
                shenpi: "",
                copy: "",
                QiniuData: {
                    fname: "", //  文件名
                    key: "", //文件名字处理
                    token: "", //七牛云token
                },
                qiniuaddr:qiniuaddr, // 七牛云的图片外链地址 你的七牛云里配置有
                uploadPath:uploadPath, // 七牛云的图片外链地址 你的七牛云里配置有
                files: [],
                restaurants: [],
                users: [],
                approvalMembers: [],
                copyMembers: [],
                requestState:false,
                speLists: {
                    teshu:[],
                    zhaoshang:[],
                },
                speTab:0
            };
        },
        created() {
            let token = genUpToken();
            this.QiniuData.token = token;
            this.$emit("setIndex", [4, 9]);
            this.id = this.$route.query.id ? this.$route.query.id : '';
            this.init();
        },
        methods: {
            setAmount(type) {
                if (type === 0) {
                    this.amounts = this.amounts.replace(/[^0-9 -.]/g, "");
                    this.self_amount = "";
                    this.meeting_decimal = "";
                } else if (type === 1) {
                    this.self_amount = this.self_amount.replace(/[^0-9 -.]/g, "");
                    if (this.amounts) {
                        if (this.self_amount) {
                            this.meeting_decimal = parseFloat((
                                Number(this.amounts).toFixed(6) - Number(this.self_amount).toFixed(6)).toFixed(6));
                        } else {
                            this.meeting_decimal = parseFloat(Number(this.amounts).toFixed(6));
                        }
                    } else {
                        if (this.self_amount && this.meeting_decimal) {
                            this.amounts = parseFloat((
                                Number(this.meeting_decimal).toFixed(6) + Number(this.self_amount).toFixed(6)).toFixed(6));
                        }
                    }
                } else {
                    this.meeting_decimal = this.meeting_decimal.replace(/[^0-9 -.]/g, "");
                    if (this.amounts) {
                        if (this.meeting_decimal) {
                            this.self_amount = parseFloat((
                                Number(this.amounts).toFixed(6) - Number(this.meeting_decimal).toFixed(6)).toFixed(6));
                        } else {
                            this.self_amount = parseFloat(Number(this.amounts).toFixed(6));
                        }
                    } else {
                        if (this.self_amount && this.meeting_decimal) {
                            this.amounts = parseFloat((
                                Number(this.meeting_decimal).toFixed(6) + Number(this.self_amount).toFixed(6)).toFixed(6));
                        }
                    }
                }
            },
            querySearch(queryString, cb) {
                const restaurants = this.restaurants;
                const results = queryString
                    ? restaurants.filter(this.createFilter(queryString))
                    : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results);
            },
            createFilter(queryString) {
                return (restaurant) => {
                    return (
                        restaurant.corporate_name
                            .toLowerCase()
                            .indexOf(queryString.toLowerCase()) !== -1
                    );
                };
            },
            querySearchUser(queryString, cb) {
                const restaurants = this.users;
                const results = queryString
                    ? restaurants.filter(this.createUserFilter(queryString))
                    : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results);
            },
            createUserFilter(queryString) {
                return (restaurant) => {
                    return (
                        restaurant.contacts
                            .toLowerCase()
                            .indexOf(queryString.toLowerCase()) !== -1
                    );
                };
            },
            subApprovalSubmit(){
                if (!this.amounts) {
                    this.$message.error("请输入协议金额");
                    return;
                }
                if (!this.zs_id) {
                    this.$message.error("请选择招商申请日期");
                    return;
                }
                if (parseFloat(Number(this.amounts).toFixed(6)) !== parseFloat(Number(this.zs_amount).toFixed(6))) {
                    let that = this;
                    that.$confirm("协议申请金额与招商资料金额不匹配", "温馨提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                        })
                        .then(() => {
                            that.subApproval()
                        })
                        .catch(() => {
                        });
                }else {
                    this.subApproval()
                }
            },
            subApproval() {
                if (!this.pid) {
                    this.$message.error(this.$refs.pName.placeholder);
                    return;
                }
                if (!this.society) {
                    this.$message.error("请选择所属学会");
                    return;
                }
                if (!this.zs_id) {
                    this.$message.error("请选择招商申请日期");
                    return;
                }
                if (!this.corporate_name) {
                    this.$message.error("请输入企业名称");
                    return;
                }
                if (!this.contacts) {
                    this.$message.error("请输入联系人");
                    return;
                }
                if (!this.amounts) {
                    this.$message.error("请输入协议金额");
                    return;
                }
                if (this.self_amount === "") {
                    this.$message.error("请输入专项金额");
                    return;
                }
                if (this.meeting_decimal === "") {
                    this.$message.error(this.$refs.meeting_decimal.placeholder);
                    return;
                }
                if (!this.zan_zhu_hui_bao) {
                    this.$message.error("请输入赞助回报项目");
                    return;
                }
                if (!this.seal_id) {
                    this.$message.error("请选择加盖何种印章");
                    return;
                }

                if (this.files.length <= 0) {
                    this.$message.error("请上传附件");
                    return;
                }
                if (!this.is_match) {
                    this.$message.error("请选择是否已核对协议金额与招商函匹配");
                    return;
                }
                if (this.shenpi.indexOf("-1") !== -1) {
                    this.$message.error("请完善财务人员");
                    return;
                }
//                let arr = this.shenpi.split(",");
//                if (arr[0] === arr[1]) {
//                    this.$message.error("审批人与财务人员不能相同");
//                    return;
//                }
                this.requestState = true
                //添加
                this.$api.apiContent
                    .approvalZz({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sid: this.society,
                        pid: this.pid.id,
                        sign_code: this.sign_code,
                        zs_id: this.zs_id,
                        name: this.name,
                        corporate_name: this.corporate_name,
                        corporate_name_remarks: this.corporate_name_remarks,
                        zan_zhu_hui_bao: this.zan_zhu_hui_bao,
                        contacts: this.contacts,
                        amount: this.amounts,
                        self_amount: this.self_amount,
                        meeting_decimal: this.meeting_decimal,
                        remark: this.remark,
                        categories: this.categories,
                        is_match: 1 ,
                        is_replied: this.is_replied,
                        files: JSON.stringify(this.files),
                        shenpi: this.shenpi,
                        copy: this.copy,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                sid: this.society,
                                pid: this.pid.id,
                                sign_code: this.sign_code,
                                zs_id: this.zs_id,
                                name: this.name,
                                corporate_name: this.corporate_name,
                                corporate_name_remarks: this.corporate_name_remarks,
                                zan_zhu_hui_bao: this.zan_zhu_hui_bao,
                                contacts: this.contacts,
                                amount: this.amounts,
                                self_amount: this.self_amount,
                                meeting_decimal: this.meeting_decimal,
                                remark: this.remark,
                                categories: this.categories,
                                is_match: 1,
                                is_replied: this.is_replied,
                                files: JSON.stringify(this.files),
                                shenpi: this.shenpi,
                                copy: this.copy,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.requestState = false
                        this.$message.success(res.msg);
                        this.$router.push({
                            name: "sponsor",
                            query: {id: res.data.id, type: 1},
                        });
                    })
                    .catch((e) => {
                        this.requestState = false
                        this.$message.error(e.msg);
                    });
            },
            classifyChange(index, view) {
                if (view === 0) {
                    let value = this.projectOptions[index];
                    value.time =
                        this.$tools.ZHDate(new Date(value.start_time * 1000)) +
                        " 至 " +
                        this.$tools.ZHDate(new Date(value.end_time * 1000));
                    this.pName = value.name;
                    this.pid = value;

                    this.contacts = "";
                    this.zs_id = "";
                    this.zs_amount = "";
                    this.categories = "";
                    this.society = "";
                    this.getSociety();
                } else if (view === 2) {
                    let item = this.merchantsOptions.filter((item) => {
                        return item.id === index;
                    });
                    this.zs_amount = item[0].amount;
                    this.categories = item[0].categories;
                    this.contacts = item[0].contacts;
                    this.getUpstreamDataSpecialEndorsement()
                }
            },
            classifyClick(event, view) {
                if (event) {
                    if (!this.pid && view === 1) {
                        this.$message.error("请选择会议名称");
                    } else if (!this.pid && view === 2) {
                        this.$message.error("请选择会议名称");
                    }
                }
            },
            getSociety() {
                this.$api.apiContent
                    .getSocietyFromPro({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        pid: this.pid.id,
                        sid: this.society,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                pid: this.pid.id,
                                sid: this.society,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.societyOptions = res.data;
                        if (res.data.length === 1 && !this.society) this.society = res.data[0].id;
                    })
                    .catch(() => {
                        this.societyOptions = [];
                    });
            },
            getMerchants() {
                this.$api.apiContent
                    .getMerchants({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.pid.id,
                        sid: this.society,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                id: this.pid.id,
                                sid: this.society,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.merchantsOptions = res.data;
                        if (this.zs_id) {
                            let item = res.data.filter((item) => {
                                return item.id === this.zs_id;
                            });
                            this.zs_amount = item[0].amount;
                        }
                    })
                    .catch(() => {
                        this.merchantsOptions = [];
                    });
            },
            getUpstreamDataSpecialEndorsement() {
                this.$api.apiContent
                    .getUpstreamDataSpecialEndorsement({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        project_id: this.pid.id,
                        zs_id: this.zs_id,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                project_id: this.pid.id,
                                zs_id: this.zs_id,
                            },
                            this.$store.state.user.key
                        ),
                    }).then((res) => {
                    res.data.teshu.map((item) => {
                        item.add_time = this.$tools.ZHDate(
                            new Date(item.add_time * 1000), 1);
                    });
                    res.data.zhaoshang.map((item) => {
                        item.add_time = this.$tools.ZHDate(
                            new Date(item.add_time * 1000), 1);
                    });
                        this.speLists = res.data;
                    })
            },
            //  页面加载时请求
            init() {
                this.$api.apiContent
                    .getProject({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.map(item=>{
                            item.name = this.$tools.ZHDate(new Date(item.start_time * 1000)) + " 至 "
                                + this.$tools.ZHDate(new Date(item.end_time * 1000)) +'/'+ item.chairman +'/'+ item. name
                        })
                        this.projectOptions = res.data;
                    })
                    .catch(() => {
                        this.projectOptions = [];
                    });
                this.$api.apiContent
                    .getAllCompanys({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.restaurants = res.data;
                    })
                    .catch(() => {
                        this.restaurants = [];
                    });
                this.$api.apiContent
                    .getUserList({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.users = res.data;
                    })
                    .catch(() => {
                        this.users = [];
                    });
                if (this.id) {
                    this.$api.apiContent
                        .getZanzhu({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.id,
                            sign: this.$tools.SHA256(
                                {
                                    token: this.$store.state.user.token,
                                    timestamp: this.$tools.requestTime(),
                                    id: this.id,
                                },
                                this.$store.state.user.key
                            ),
                        })
                        .then((res) => {
//            this.approvalMembers = res.data.shenpi;
//            this.shenpi = res.data.shenpi
//              .map((item) => {
//                return item.user_id;
//              })
//              .join(",");
                            this.copyMembers = res.data.copy;
                            this.copy = res.data.copy
                                .map((item) => {
                                    return item.user_id;
                                })
                                .join(",");
                            this.sign_code = res.data.sign_code;
                            this.society = res.data.sid;
                            res.data.project.time =
                                this.$tools.ZHDate(
                                    new Date(res.data.project.start_time * 1000)) +
                                " 至 " +
                                this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                            res.data.project.meet_form_str = res.data.meet_form_str
                            res.data.project.id = res.data.pid;
                            this.pid = res.data.project;
                            this.pName = this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) + " 至 "
                                + this.$tools.ZHDate(new Date(res.data.project.end_time * 1000)) + '/' + res.data.project.chairman + '/' +  res.data.project.name;
                            this.zs_id = res.data.zs_id;
                            this.name = res.data.name;
                            this.corporate_name_remarks = res.data.corporate_name_remarks;
                            this.zan_zhu_hui_bao = res.data.zan_zhu_hui_bao;
                            this.corporate_name = res.data.corporate_name;
                            this.contacts = res.data.contacts;
                            this.amounts = parseFloat(Number(res.data.amount).toFixed(6));
                            this.self_amount = parseFloat(Number(res.data.self_amount).toFixed(6));
                            this.meeting_decimal = parseFloat(Number(res.data.meeting_decimal).toFixed(6));
                            this.remark = res.data.remark;
                            this.is_match = true;
                            this.is_replied = res.data.is_replied;
                            this.categories = res.data.categories;
                            this.files = res.data.files;
                            this.getSociety();
                            this.getUpstreamDataSpecialEndorsement();
                        })
                        .catch(() => {
                        });
                }
            },
            //  上传成功
            uploadSuccess(response, file, fileList) {
                console.log(file);
                console.log(fileList);
                this.files.push({
                    filePath: `${this.qiniuaddr}/${response.key}`,
                    //在这里你就可以获取到上传到七牛的外链URL了
                    fileName: file.name,
                });

                this.loading.close();
            },
            closeFile(position) {
                this.$confirm("此操作将删除该文件, 是否继续?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                })
                    .then(() => {
                        this.files.splice(position, 1);
                    })
                    .catch();
            },
            //  上传失败
            uploadError() {
                this.loading.close();
                this.$message({
                    message: "上传出错，请重试！",
                    type: "error",
                    center: true,
                });
            },
            beforeAvatarUpload(file) {
                //                const isJPG = file.type === 'application/pdf';
                //                if (!isJPG) {
                //                    this.$message.error('只能上传PDF格式!');
                //                    return
                //                }
                //这里的key给加上了时间戳，目的是为了防止上传冲突
                this.QiniuData.fname = file.name;
                this.QiniuData.key = `${new Date().getTime()}${file.name}`;
                this.loading = this.$loading({
                    lock: true,
                    text: "上传中，请稍后",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            },
            backPage() {
                this.$tools.goBack();
            },
            //审批人
            setApproval(members) {
                this.shenpi = members;
            },
            //抄送人
            setMember(members) {
                this.copy = members;
            },
        },
        watch: {
            society() {
                this.getMerchants();
            },
        },
        filters: {
            filePath(url) {
                return webUrl(url)
            },
        },
    };
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }
        .info-list{
            border: 1px solid #e1e1e1;
            max-height: 800px;
            overflow-x: auto;
            .tab {
                color: #000000;
                font-size: 20px;
                cursor: pointer;
                &.active {
                    color: #3b77e7;
                    font-size: 22px;
                }
            }
            .info-item{
                border: 1px solid #e1e1e1;
                margin-top: 20px;
                padding: 15px;
                .name{
                    font-size: 18px;
                    color: #333333;
                }
                .remark{
                    font-size: 16px;
                    color: #666666;
                }
                .file {
                    display: flex;
                    flex-direction: column;
                    a {
                        font-size: 18px;
                        cursor: pointer;
                        margin-bottom: 8px;
                        display: block;
                        color: #3b77e7;

                        &:hover {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        .sub-sponsor-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            padding: 40px 25px;
            min-height: 800px;
            span {
                color: #000000;
                font-size: 24px;
            }
            .sub-sponsor-container {
                width: 60%;
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                flex-shrink: 0;
                .input-item {
                    .upfile-list {
                        width: 400px;
                        margin-bottom: 12px;
                        li {
                            height: 31px;
                            border-bottom: 1px dashed #ccc;

                            a {
                                display: block;
                                width: 350px;
                                height: 30px;
                                line-height: 30px;
                                color: #3b77e7;
                            }

                            .el-icon-delete {
                                font-size: 16px;
                                cursor: pointer;

                                &:hover {
                                    color: #3b77e7;
                                }
                            }
                        }
                    }
                    .money {
                        flex-shrink: 0;
                        margin-left: 10px;
                    }
                    display: flex;
                    margin-bottom: 20px;
                    flex-direction: row;
                    align-items: center;
                    overflow: hidden;
                    .input-item-title {
                        width: 20%;
                        text-align: center;
                        font-size: 16px;
                        margin-right: 20px;
                        color: #000000;
                        flex-shrink: 0;
                    }
                    .input-item-desc {
                        width: 20%;
                        align-self: flex-start;
                        margin-top: 10px;
                        margin-right: 20px;
                        text-align: center;
                        font-size: 16px;
                        color: #000000;
                        flex-shrink: 0;
                    }
                    .el-input,
                    .el-autocomplete,
                    .el-select,
                    .el-textarea,
                    .el-radio-group,
                    .el-date-editor,
                    .el-upload {
                        flex-grow: 1;
                        resize: none;
                    }
                }
                > .el-button {
                    margin-top: 30px;
                    align-self: center;
                    width: 40%;
                }
            }
        }
    }
</style>
